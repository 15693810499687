<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent="$emit('on-Submit', PageMetaEditForm)">
        <b-card-code :title="pageTitle" class="mt-2">
          <b-row>
            <b-col>
              <h4>Meta:</h4>
            </b-col>
          </b-row>

          <b-row
            class="mb-2 mt-1 mr-1"
            v-for="(item, index) in PageMetaEditForm.seo_meta"
            :id="item.id"
            :key="index"
            ref="seoMetaRow"
          >
            <b-col
              md="12"
              v-if="
                PageMetaEditForm.seo_meta[index].key != 'robots' &&
                PageMetaEditForm.seo_meta[index].key != 'type'
              "
            >
              <validation-provider
                #default="{ errors }"
                :name="upperCaseFunc(PageMetaEditForm.seo_meta[index].key)"
                :rules="
                  PageMetaEditForm.seo_meta[index].key == 'title'
                    ? 'required|max:60|min:1'
                    : PageMetaEditForm.seo_meta[index].key == 'description'
                    ? 'required|max:160|min:1'
                    : PageMetaEditForm.seo_meta[index].key == 'canonical'
                    ? '': 'required|min:1'
                "
              >
                <b-form-group
                  :label="upperCaseFunc(PageMetaEditForm.seo_meta[index].key)"
                  label-for="mc-value"
                >
                  <template>
                    <div v-if="PageMetaEditForm.seo_meta[index].key !== 'canonical'">
                      <b-form-textarea
                        v-model="PageMetaEditForm.seo_meta[index].value"
                        id="textarea-default"
                        rows="2"
                      />
                    </div>
                    <b-input-group
                      v-if="PageMetaEditForm.seo_meta[index].key == 'canonical'"
                      class="input-group-merge"
                      :prepend="frontendPath"
                    >
                      <b-form-input
                        v-model="PageMetaEditForm.seo_meta[index].value"
                        id="textarea-default"
                        class="form-control-merge"
                        disabled
                      />
                    </b-input-group>
                  </template>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              md="12"
              v-if="PageMetaEditForm.seo_meta[index].key == 'robots'"
            >
              <label for="Robots"><strong>Robots</strong></label>
              <b-row>
                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Index"
                    rules="required"
                  >
                    <b-form-group
                      label="Index"
                      label-for="mc-index"
                      :state="errors.length > 0 ? false : null"
                    >
                      <v-select
                        v-model="PageMetaEditForm.seo_meta[index].value.index"
                        placeholder="Select Index"
                        :state="errors.length > 0 ? false : null"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="RobotIndexTypes"
                        :reduce="(val) => val.value"
                        :clearable="false"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Follow"
                    rules="required"
                  >
                    <b-form-group
                      label="Follow"
                      label-for="mc-follow"
                      :state="errors.length > 0 ? false : null"
                    >
                      <v-select
                        v-model="PageMetaEditForm.seo_meta[index].value.follow"
                        placeholder="Select Follow"
                        :state="errors.length > 0 ? false : null"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="RobotFollowTypes"
                        :reduce="(val) => val.value"
                        :clearable="false"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-col>
            <b-col v-if="PageMetaEditForm.seo_meta[index].key == 'type'">
              <validation-provider
                #default="{ errors }"
                name="Open Graph Type"
                rules="required"
              >
                <b-form-group
                  label="Open Graph Type"
                  label-for="mc-og-type"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    v-model="PageMetaEditForm.seo_meta[index].value"
                    placeholder="Select Open Graph Type"
                    :state="errors.length > 0 ? false : null"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="OGTypes"
                    :reduce="(val) => val.value"
                    :clearable="false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <label for="meta-image">Featured Image</label>
            </b-col>
            <b-col cols="12" md="12" class="mt-2">
              <div class="helper" />
              <div class="drop align-center" @dragover.prevent @drop="onDrop">
                <div class="helper" />
                <label
                  v-if="!imagePreview && !PageMetaEditForm.image"
                  class="btn btn-primary display-inline"
                >
                  SELECT OR DROP AN IMAGE
                  <input type="file" name="image" @change="onChange" />
                </label>
                <div
                  v-if="imagePreview"
                  class="display-inline align-center"
                  :class="{ image: true }"
                >
                  <img
                    :src="imagePreview"
                    alt="Image"
                    class="img imagePreview_Cstm mt-2"
                  />
                  <br />
                  <br />
                  <label class="btn btn-primary mb-2">
                    REPLACE
                    <input type="file" name="image" @change="onChange" />
                  </label>
                </div>

                <div
                  v-if="
                    PageMetaEditForm.image && !imagePreview && $route.params.id
                  "
                  class="display-inline align-center"
                  :class="{ image: true }"
                >
                  <img
                    :src="PageMetaEditForm.image"
                    alt="Image"
                    class="img imagePreview_Cstm mt-2"
                  />
                  <br />
                  <br />
                  <label class="btn btn-primary mb-2">
                    REPLACE
                    <input type="file" name="image" @change="onChange" />
                  </label>
                </div>
              </div>

              <b-alert class="mt-2" variant="primary" show>
                <div class="alert-body">
                  <span>Allowed JPG, JPEG, GIF or PNG. Max size of 1MB.</span>
                </div>
              </b-alert>
            </b-col>
          </b-row>
          <!-- <b-row>
            <b-col>
              <b-button
                :style="`cursor`"
                class="float-right p-1"
                variant="success"
                @click="repeateAgain"
              >
                <feather-icon icon="PlusIcon" size="22"/>
              </b-button>
            </b-col>
          </b-row>
           -->
        </b-card-code>
        <b-row>
          <b-col md="12" class="demo-inline-spacing">
            <!-- <b-button
              :disabled="publishLoading"
              variant="success"
              type="submit"
              @click="checkPublishMethod('publish')"
            >
              Save & Publish
              <div
                v-if="publishLoading"
                class="spinner-border spinner-border-sm"
              />
            </b-button> -->
            <b-button
              variant="primary"
              type="submit"
              :disabled="loading"
              @click="updatePageMeta()"
            >
              Save
              <div v-if="loading" class="spinner-border spinner-border-sm" />
            </b-button>
            <b-button variant="secondary" :to="{ name: 'page-meta' }">
              Cancel
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BAlert,
  BCol,
  BButton,
  BInputGroupAppend,
  BFormTextarea,
} from "bootstrap-vue";
import {
  required,
  email,
  confirmed,
  alpha,
} from "@core/utils/validations/validations";
import pageMetaService from "@/services/page-meta/pageMeta.service";
// eslint-disable-next-line
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import errorResponseHandler from "@/services/errorHandler";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  RoleEnum,
  resConditionCheck,
  priority,
  RobotIndexTypes,
  RobotFollowTypes,
  OGTypes,
} from "@/helpers/constant";
import vSelect from "vue-select";
import checkLoginRole from "@/helpers/checkLoginRole";

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    quillEditor,
    vSelect,
    BInputGroupAppend,
    BFormTextarea,
    BAlert,
  },

  data() {
    return {
      PageMetaEditForm: {
        page_type: "",
        seo_meta: "",
      },
      checkLoginRole,
      required,
      email,
      image: this.PageMetaEditForm?.image || "",
      confirmed,
      alpha,
      loading: false,
      toolbarOptions: [],
      snowOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike", "align", "link"],
            [{ list: "ordered" }, { list: "bullet" }],
          ],
        },
      },
      priority,
      RoleEnum,
      deleteMsg: "",
      titleMsg: "",
      imagePreview: "",
      publishLoading: false,
      resConditionCheck,
      RobotIndexTypes,
      RobotFollowTypes,
      OGTypes,
    };
  },
  computed: {
    pageTitle() {
      const title = this.PageMetaEditForm.page_type.replace("_", " ");
      return title.charAt(0).toUpperCase() + title.slice(1);
    },
  },
  mounted() {
    this.getPageMetaById(this.$route.params.id);
  },

  methods: {
    onDrop(e) {
      e.stopPropagation();
      e.preventDefault();
      const { files } = e.dataTransfer;
      this.createFile(files[0]);
    },

    onChange(e) {
      const { files } = e.target;
      this.createFile(files[0]);
    },

    createFile(file) {
      if (
        (file.type === "image/jpeg" ||
          file.type === "image/jpg" ||
          file.type === "image/png" ||
          file.type === "image/gif") &&
        file.size <= 1048576
      ) {
        this.PageMetaEditForm.image = file;
        this.imagePreview = URL.createObjectURL(file, file.name);
      } else {
        this.PageMetaEditForm.image = null;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Only JPG, JPEG, GIF or PNG Allowed with Max Size Of 1 MB",
            icon: "X-CircleIcon",
            variant: "danger",
          },
        });
      }
    },

    removeFile() {
      if (this.PageMetaEditForm.image) {
        this.PageMetaEditForm.image = "";
      } else {
        this.imagePreview = "";
      }
    },

    disabledRange(date) {
      let year = moment(this.savedTrip.payload.checkin_date).format("YYYY");
      let day = moment(this.savedTrip.payload.checkin_date)
        .subtract(1, "day")
        .format("D");
      let month =
        moment(this.savedTrip.payload.checkin_date).format("MM") === "01"
          ? "0"
          : moment(this.savedTrip.payload.checkin_date)
              .subtract(1, "month")
              .format("M");
      return date < new Date() || date > new Date(year, month, day);
    },

    repeateAgain() {
      this.PageMetaEditForm.seo_meta.push({
        key: "",
        value: "",
        type: "",
      });

      this.$nextTick(() => {
        // this.trAddHeight(this.$refs.seoMetaRow[0].offsetHeight)
      });
    },

    async getPageMetaById(id) {
      await pageMetaService
        .getPageMetaById(id)
        .then((res) => {
          if (resConditionCheck(res.status) && res.data.data) {
            this.PageMetaEditForm.page_type = res.data.data.pageType;

            // console.log('res.data.data.seoMeta-->', res.data.data.seoMeta);
            for (let i = 0; i < res.data.data.seoMeta.length; i++) {
              if (!res.data.data.seoMeta[i].hasOwnProperty("key")) {
                res.data.data.seoMeta[i] = {
                  key: "",
                  value: "",
                };
              }
            }

            const seoMeta =
              res.data.data.seoMeta && res.data.data.seoMeta.length
                ? res.data.data.seoMeta.filter((e) => e.key != "image")
                : [];
            this.PageMetaEditForm.seo_meta =
              seoMeta && seoMeta.length
                ? seoMeta
                : [
                    { key: "title", value: "" },
                    { key: "description", value: "" },
                    { key: "canonical", value: "" },
                    {
                      key: "robots",
                      value: {
                        index: "index",
                        follow: "follow",
                      },
                    },
                    { key: "type", value: "website" },
                  ];
            const metaImage = res.data.data.seoMeta.filter(
              (e) => e.key == "image"
            );
            this.imagePreview =
              metaImage && metaImage.length > 0 ? metaImage?.[0].value : null;
          }
        })
        .catch((error) => {
          const errorData = errorResponseHandler(error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: errorData,
              icon: "X-CircleIcon",
              variant: "danger",
            },
          });
        });
    },

    async updatePageMeta() {
      const submitStatus = await this.$refs.simpleRules.isPublished;
      let valid = null;
      await this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          valid = true;
        } else {
          this.$refs.simpleRules.validate();
          valid = false;
        }
      });
      if (!this.imagePreview) {
        if (submitStatus === "save") {
          this.$refs.simpleRules.loading = true;
          setTimeout(() => {
            this.$refs.simpleRules.loading = false;
          }, 3000);
        }

        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Featured Image is required",
            icon: "X-CircleIcon",
            variant: "danger",
          },
        });
      }
      if ((valid && this.PageMetaEditForm.image) || this.imagePreview) {
        if (submitStatus === "save") {
          this.$refs.simpleRules.loading = true;
        }
        const imageForm = Object.assign(this.PageMetaEditForm);
        const clonedForm = JSON.parse(JSON.stringify(this.PageMetaEditForm));
        const newForm = Object.assign(clonedForm);
        const formdata = new FormData();
        formdata.append("image", imageForm.image);
        formdata.append("page_type", newForm.page_type);
        formdata.append("seo_meta", JSON.stringify(newForm.seo_meta));

        this.$refs.simpleRules.validate().then((success) => {
          if (success) {
            this.loading = true;
            pageMetaService
              .editPageMeta(this.$route.params.id, formdata)
              .then((res) => {
                if (resConditionCheck(res.status) && res.data.data) {
                  this.loading = false;
                  this.$router.push("/content/page-meta");
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: res.data.message,
                      icon: "CheckIcon",
                      variant: "success",
                    },
                  });
                }
              })
              .catch((error) => {
                const errorData = errorResponseHandler(error);
                this.loading = false;
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: errorData,
                    icon: "X-CircleIcon",
                    variant: "danger",
                  },
                });
              });
          }
        });
      }
    },
    upperCaseFunc(data) {
      const title = data.replace("_", " ");
      return title.charAt(0).toUpperCase() + title.slice(1);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.ql-editor {
  min-height: 150px;
  max-height: 350px;
}
.dropBtn {
  background-color: #d3394c;
  border: 0;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  padding: 15px 35px;
  position: relative;
}

.dropBtn:hover {
  background-color: #722040;
}

input[type="file"] {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.align-center {
  text-align: center;
}

// .helper {
//   height: 100%;
//   display: inline-block;
//   vertical-align: middle;
//   width: 0;
// }

.hidden {
  display: none !important;
}

.hidden.image {
  display: inline-block !important;
}

.display-inline {
  display: inline-block;
  vertical-align: middle;
}

.imagePreview_Cstm {
  border: 1px solid #f6f6f6;
  display: inline-block;
  width: 400px;
  height: 250px;
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
  padding: 0 10px;
}

.drop {
  background-color: #f2f2f2;
  border: 4px dashed #ccc;
  background-color: #f6f6f6;
  border-radius: 2px;
  height: 400px;
  width: 400px;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.drop .btn-primary {
  padding-left: 10px;
  padding-right: 10px;
}

.seoMetaRemove {
  cursor: pointer;
  border-radius: 50%;
  transition: 0.25s ease-in-out;
}
.seoMetaRemove:hover {
  background: rgb(187, 14, 14);
  color: #fff;
  box-shadow: 0px 0px 10px 2px #efefef;
}
</style>

<style>
.fileUpload {
  position: relative;
  overflow: hidden;
  margin: 10px;
}
/* .fileUpload input.upload {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    font-size: 20px;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
} */
</style>
