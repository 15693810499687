import axios from 'axios'

function getPageMetaListing(payload) {
  return axios.get('page-meta/list', { params: payload })
}

function editPageMeta(id, data) {
  return axios.put(`page-meta/${id}`, data)
}

function getPageMetaById(id) {
  return axios.get(`page-meta/${id}`)
}

export default {
  getPageMetaListing,
  editPageMeta,
  getPageMetaById
}
