var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.$emit('on-Submit', _vm.PageMetaEditForm)}}},[_c('b-card-code',{staticClass:"mt-2",attrs:{"title":_vm.pageTitle}},[_c('b-row',[_c('b-col',[_c('h4',[_vm._v("Meta:")])])],1),_vm._l((_vm.PageMetaEditForm.seo_meta),function(item,index){return _c('b-row',{key:index,ref:"seoMetaRow",refInFor:true,staticClass:"mb-2 mt-1 mr-1",attrs:{"id":item.id}},[(
              _vm.PageMetaEditForm.seo_meta[index].key != 'robots' &&
              _vm.PageMetaEditForm.seo_meta[index].key != 'type'
            )?_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":_vm.upperCaseFunc(_vm.PageMetaEditForm.seo_meta[index].key),"rules":_vm.PageMetaEditForm.seo_meta[index].key == 'title'
                  ? 'required|max:60|min:1'
                  : _vm.PageMetaEditForm.seo_meta[index].key == 'description'
                  ? 'required|max:160|min:1'
                  : _vm.PageMetaEditForm.seo_meta[index].key == 'canonical'
                  ? '': 'required|min:1'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.upperCaseFunc(_vm.PageMetaEditForm.seo_meta[index].key),"label-for":"mc-value"}},[[(_vm.PageMetaEditForm.seo_meta[index].key !== 'canonical')?_c('div',[_c('b-form-textarea',{attrs:{"id":"textarea-default","rows":"2"},model:{value:(_vm.PageMetaEditForm.seo_meta[index].value),callback:function ($$v) {_vm.$set(_vm.PageMetaEditForm.seo_meta[index], "value", $$v)},expression:"PageMetaEditForm.seo_meta[index].value"}})],1):_vm._e(),(_vm.PageMetaEditForm.seo_meta[index].key == 'canonical')?_c('b-input-group',{staticClass:"input-group-merge",attrs:{"prepend":_vm.frontendPath}},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"textarea-default","disabled":""},model:{value:(_vm.PageMetaEditForm.seo_meta[index].value),callback:function ($$v) {_vm.$set(_vm.PageMetaEditForm.seo_meta[index], "value", $$v)},expression:"PageMetaEditForm.seo_meta[index].value"}})],1):_vm._e()],_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],2)]}}],null,true)})],1):_vm._e(),(_vm.PageMetaEditForm.seo_meta[index].key == 'robots')?_c('b-col',{attrs:{"md":"12"}},[_c('label',{attrs:{"for":"Robots"}},[_c('strong',[_vm._v("Robots")])]),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Index","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Index","label-for":"mc-index","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"placeholder":"Select Index","state":errors.length > 0 ? false : null,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.RobotIndexTypes,"reduce":function (val) { return val.value; },"clearable":false},model:{value:(_vm.PageMetaEditForm.seo_meta[index].value.index),callback:function ($$v) {_vm.$set(_vm.PageMetaEditForm.seo_meta[index].value, "index", $$v)},expression:"PageMetaEditForm.seo_meta[index].value.index"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Follow","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Follow","label-for":"mc-follow","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"placeholder":"Select Follow","state":errors.length > 0 ? false : null,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.RobotFollowTypes,"reduce":function (val) { return val.value; },"clearable":false},model:{value:(_vm.PageMetaEditForm.seo_meta[index].value.follow),callback:function ($$v) {_vm.$set(_vm.PageMetaEditForm.seo_meta[index].value, "follow", $$v)},expression:"PageMetaEditForm.seo_meta[index].value.follow"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1)],1):_vm._e(),(_vm.PageMetaEditForm.seo_meta[index].key == 'type')?_c('b-col',[_c('validation-provider',{attrs:{"name":"Open Graph Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Open Graph Type","label-for":"mc-og-type","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"placeholder":"Select Open Graph Type","state":errors.length > 0 ? false : null,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.OGTypes,"reduce":function (val) { return val.value; },"clearable":false},model:{value:(_vm.PageMetaEditForm.seo_meta[index].value),callback:function ($$v) {_vm.$set(_vm.PageMetaEditForm.seo_meta[index], "value", $$v)},expression:"PageMetaEditForm.seo_meta[index].value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1):_vm._e()],1)}),_c('b-row',[_c('b-col',[_c('label',{attrs:{"for":"meta-image"}},[_vm._v("Featured Image")])]),_c('b-col',{staticClass:"mt-2",attrs:{"cols":"12","md":"12"}},[_c('div',{staticClass:"helper"}),_c('div',{staticClass:"drop align-center",on:{"dragover":function($event){$event.preventDefault();},"drop":_vm.onDrop}},[_c('div',{staticClass:"helper"}),(!_vm.imagePreview && !_vm.PageMetaEditForm.image)?_c('label',{staticClass:"btn btn-primary display-inline"},[_vm._v(" SELECT OR DROP AN IMAGE "),_c('input',{attrs:{"type":"file","name":"image"},on:{"change":_vm.onChange}})]):_vm._e(),(_vm.imagePreview)?_c('div',{staticClass:"display-inline align-center",class:{ image: true }},[_c('img',{staticClass:"img imagePreview_Cstm mt-2",attrs:{"src":_vm.imagePreview,"alt":"Image"}}),_c('br'),_c('br'),_c('label',{staticClass:"btn btn-primary mb-2"},[_vm._v(" REPLACE "),_c('input',{attrs:{"type":"file","name":"image"},on:{"change":_vm.onChange}})])]):_vm._e(),(
                  _vm.PageMetaEditForm.image && !_vm.imagePreview && _vm.$route.params.id
                )?_c('div',{staticClass:"display-inline align-center",class:{ image: true }},[_c('img',{staticClass:"img imagePreview_Cstm mt-2",attrs:{"src":_vm.PageMetaEditForm.image,"alt":"Image"}}),_c('br'),_c('br'),_c('label',{staticClass:"btn btn-primary mb-2"},[_vm._v(" REPLACE "),_c('input',{attrs:{"type":"file","name":"image"},on:{"change":_vm.onChange}})])]):_vm._e()]),_c('b-alert',{staticClass:"mt-2",attrs:{"variant":"primary","show":""}},[_c('div',{staticClass:"alert-body"},[_c('span',[_vm._v("Allowed JPG, JPEG, GIF or PNG. Max size of 1MB.")])])])],1)],1)],2),_c('b-row',[_c('b-col',{staticClass:"demo-inline-spacing",attrs:{"md":"12"}},[_c('b-button',{attrs:{"variant":"primary","type":"submit","disabled":_vm.loading},on:{"click":function($event){return _vm.updatePageMeta()}}},[_vm._v(" Save "),(_vm.loading)?_c('div',{staticClass:"spinner-border spinner-border-sm"}):_vm._e()]),_c('b-button',{attrs:{"variant":"secondary","to":{ name: 'page-meta' }}},[_vm._v(" Cancel ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }